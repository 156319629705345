import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import About from "./about";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import logo from "../../Assets/main/home.jpg";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className="home-content">
          <div className="home-content-top">
            <marquee className="home-text" scrollamount={18}>
              <img src={logo} alt="home-img" className="home-logo" />
              <span style={{ color: "red" }}>THE</span>
              <span style={{ color: "green" }}>KING</span>
              <span style={{ color: "blue" }}>OF</span>
              <span style={{ color: "yellow" }}>MEMES</span>
              <span style={{ color: "purple" }}>IS</span>
              <span style={{ color: "pink" }}>HERE</span>
              <img src={logo} alt="home-img" className="home-logo" />
            </marquee>
            <div className="home-text2">
              <span>CONTRACT: COMING SOON</span>
            </div>
          </div>
        </div>
        <About />
        <Tokenomics />
        <Roadmap />
      </Container>
    </section>
  );
}

export default Home;
