import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/main/logo.jpg";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <div className="about-content">
        <Row className="about-top">
          <Col>
            <p className="about-text">
              Welcome to REKT - everything you've wanted in a crypto project but
              you've been too afraid to ask.
            </p>
            <p className="about-text about-text2">
              Building an energetic community, our members will make you blush
              and your wallets flush. Join us as we come together and build more
              than just a meme.
            </p>
            <p className="about-text about-text3">
              Everyone should get a little REKT.
            </p>
          </Col>
          <Col>
            <img src={logo} alt="home-img" className="about-logo" width={500} />
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default About;
