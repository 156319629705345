import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RoadmapContent from "./RoadmapContent";

function Roadmap() {
  return (
    <Container fluid className="roadmap-section" id="roadmap">
      <Container>
        <Row className="roadmap">
          <Col md={6} className="roadmap-left">
            <h3 className="roadmap-title">Q1</h3>
            <RoadmapContent title="Build community ✔️" content={[]} />
            <RoadmapContent title="Launch on Uniswap ✔️" content={[]} />
            <RoadmapContent title="Website Release ✔️" content={[]} />
            <RoadmapContent title="Marketing Campaign  ✔️" content={[]} />
          </Col>
          <Col md={6} className="roadmap-right">
            <h3 className="roadmap-title">Q2</h3>
            <RoadmapContent title="Community Event ✔️" content={[]} />
            <RoadmapContent title="Whitepaper Release ⌛" content={[]} />
            <RoadmapContent title="Telegram Influencers ⌛" content={[]} />
            <RoadmapContent title="X Influencers ⌛" content={[]} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Roadmap;
